.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #b7c5c2;
}
.table--delete {
  color: #019bf0;
  cursor: pointer;
}
.table--edit {
  // color: #f7e92c;
  background-color: yellow;
  cursor: pointer;
}
.table {
  @include directify($directions) {
    text-align: directed("left");
  }

  &:last-child {
    margin-bottom: 0;
  }

  tbody td,
  th {
    // border: none;
    padding: 10px;

    @include themify($themes) {
      color: themed("colorTopbar");
    }
  }

  thead th {
    @include themify($themes) {
      color: themed("colorTopbar");
      // border-bottom-color: themed('colorBorder');
    }
  }

  .badge {
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;

    &.badge-success {
      background-color: $color-accent;
    }

    &.badge-warning {
      background-color: $color-yellow;
    }

    &.badge-primary {
      background-color: $color-blue;
    }

    &.badge-danger {
      background-color: $color-red;
    }

    &.badge-disabled {
      background-color: #dddddd;
    }
  }

  &.table--bordered {
    // column-span: 200px;

    @include directify($directions) {
      text-align: directed("center");
    }

    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed("colorBorder");
      }

      &:last-child {
        border-bottom: none;
      }
      th {
        color: #1c92ff;
      }
    }
  }

  &.table--head-accent {
    thead {
      @include themify($themes) {
        background-color: themed("colorHover");
      }

      th {
        border-bottom: none;
        border-top: none;
      }
    }
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      @include themify($themes) {
        background-color: themed("colorHover");
      }
    }
  }

  &.table-hover {
    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        @include themify($themes) {
          background-color: themed("colorBackgroundBody");
        }
      }
    }
  }
}
